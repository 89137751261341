.ad-favorite-button-icon {
  padding: 0 10px;
}

.ad-favorite-button.fixed {
  position: absolute;
  bottom: 10px;
}

/* Default */
:not([dir]) .ad-favorite-button.fixed {
  right: 10px;
}

[dir=ltr] .ad-favorite-button.fixed {
  right: 10px;
}

[dir=rtl] .ad-favorite-button.fixed {
  left: 10px;
}