.ad-category-square {
  background-color: #fff;
  margin: 5px;
}

.ad-category-square.disabled{
  pointer-events: none;
  opacity: .25;
}

.ad-category-square-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0;
  padding: 5px;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  font-weight: bold;
}