.swiper .swiper-slide {
  height: auto;
  max-height: 35vh;
}

.swiper .swiper-slide .slide-image {
  height: 100%;
}

.swiper .swiper-slide .slide-image::part(image) {
  align-self: stretch;
  object-fit: cover;
}

.swiper .swiper-pagination-bullet {
  --swiper-pagination-bullet-inactive-color: var(--adzat-light-color);
}

.swiper .swiper-pagination-bullet-active {
  --swiper-pagination-color: var(--adzat-main-color);
}
.swiper-button-next,
.swiper-button-prev {
  --swiper-navigation-color: var(--adzat-main-color);
}